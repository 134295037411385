@use "../../scss/functions" as fn;

.page-header {
    padding: 18px 0;
    position: sticky;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .page-header__content {
        width: 100%;

        .page-header__title {
            display: flex;
            align-items: center;
            gap: 10px;

            h1 {
                flex-grow: 1;
            }
        }

        .switch-btn {
            display: flex;
            align-items: center;
            padding: 6px 16px;
            margin: 0 12px;
            font-weight: 500;
            background-color: var(--block-item1-selected-teacher);
            border: none;
        }
    }

    .page-header__title-wrapper > *:first-child:not(:only-child) {
        margin-bottom: 0;
    }

    h1 {
        font-weight: 500;
        margin-bottom: 0;
        font-size: fn.pxToRem(28);
        color: var(--text-main-default);
    }

    p {
        color: var(--text-main-default);
        font-size: fn.pxToRem(28);
    }
}

@media screen and (max-width: 768px) {
    .page-header {
        background-color: var(--block-header);

        h1 {
            font-size: fn.pxToRem(18);
            font-weight: 400;
        }

        .page-header__title {
            flex-wrap: wrap;
        }

        .page-header__children {
            display: flex;
            margin-top: 16px;

            > * {
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }
}
