.popover-menu__menu {
    background: var(--block-header);
    border-radius: 4px;
    color: var(--text-main-default);
    border: 1px solid var(--element-grey20);
    width: 257px;

    .popover-menu__item {
        display: flex;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        color: var(--text-main-default);

        > * {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--text-main-default);
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.popover-menu__trigger {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--text-main-default);
    cursor: pointer;
    border-radius: 6px;
    padding: 8px;
    border: 1px solid transparent;

    &:hover,
    &[aria-expanded="true"] {
        border-color: var(--element-grey20);
        background: var(--block-item2-default);
    }

    &:focus-visible {
        outline: 1px solid;
    }
}
