@use "../../design-system-components/TriStateCheckbox/TriStateCheckbox.scss" as
    checkbox;
@use "@evidenceb/athena-common/sass-helpers/accordion" as accordion;

$checkbox-right-margin: 12px;
$btn-gap: 5px;
$btn-padding: 10px;

$clickableSize: 44px;

@mixin indent {
    @for $i from 1 through 6 {
        &.--indent-#{$i} {
            margin-left: calc($i * 16px);
        }
    }
}

@mixin btn {
    background: rgba(88, 164, 207, 0.1);
    border-radius: 6px;
    border: 2px solid transparent;
    align-items: center;
}

@mixin active-btn {
    background-color: var(--accent-color, var(--color-secondary));
    color: var(--text-main-inverted);
}

@mixin hovered-item {
    border: 2px solid transparent;

    &:hover {
        border-color: var(--white);
    }
}

@mixin hovered-item-not-selected {
    background: var(
        --block-item1-selected,
        linear-gradient(
            32deg,
            rgba(19, 92, 179, 0.26) 0%,
            rgba(67, 182, 218, 0.26) 58.33%,
            rgba(153, 191, 248, 0.26) 100%
        )
    );
}

.tree-checkbox {
    position: relative;
    isolation: isolate;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1rem;
        font-weight: 500;
    }

    fieldset {
        border: none;
    }
}

.tree-checkbox-list__title {
    position: relative;
    margin-bottom: $btn-gap;
    isolation: isolate;
    display: flex;
    flex-direction: row;
    color: var(--text-main-default);
    text-align: left;
    @include indent;
    @include hovered-item;

    &.--btn {
        @include btn;
    }

    &:not(.tree-checkbox-list__title--active):hover {
        @include hovered-item-not-selected;
    }

    &.tree-checkbox-list__title--active {
        @include active-btn;

        .tree-checkbox-list__title-content {
            color: var(--text-main-inverted);
        }
    }
}

.tree-checkbox-list__title-content {
    padding: $btn-padding;
    padding-right: calc(
        $checkbox-right-margin + $btn-padding + checkbox.$size +
            ($clickableSize - checkbox.$size) / 2
    );
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-main-default);
    text-align: left;
}

.tree-checkbox-list__title-checkbox {
    position: absolute;
    top: 0;
    width: $clickableSize;
    height: $clickableSize;
    right: $checkbox-right-margin;
    z-index: 100;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
        background: var(
            --block-item1-selected,
            linear-gradient(
                32deg,
                rgba(19, 92, 179, 0.26) 0%,
                rgba(67, 182, 218, 0.26) 58.33%,
                rgba(153, 191, 248, 0.26) 100%
            )
        );
    }
}

.tree-checkbox-list__leaf {
    display: flex;
    justify-content: space-between;
    padding: $btn-padding;
    @include btn;
    @include indent;
    color: var(--text-main-default);
    position: relative;
    @include hovered-item;

    button {
        color: inherit;
        flex: 1;
        text-align: start;

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &:focus-visible {
            outline: 12px solid var(--text-main-inverted);
        }
    }

    .leaf__checkbox {
        margin-right: calc($checkbox-right-margin - $btn-padding);
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $clickableSize;
        height: $clickableSize;

        &:only-child {
            cursor: pointer;
            flex: 1;
        }
    }

    &.--with-click-handler {
        padding-right: calc(
            $checkbox-right-margin + $btn-padding + checkbox.$size +
                ($clickableSize - checkbox.$size) / 2
        );

        .leaf__checkbox {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: $checkbox-right-margin;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                background: var(
                    --thumbnail-media-illustration-fill-hover,
                    linear-gradient(
                        32deg,
                        rgba(19, 92, 179, 0.26) 0%,
                        rgba(67, 182, 218, 0.26) 58.33%,
                        rgba(153, 191, 248, 0.26) 100%
                    )
                );
            }
        }
    }

    &.--active {
        @include active-btn;
        color: var(--text-main-inverted);
    }

    &:not(.--active):hover {
        @include hovered-item-not-selected;
    }
}

li:not(:last-child) .tree-checkbox-list__leaf {
    margin-bottom: $btn-gap;
}

.leaf__label {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-inline-start: calc(accordion.$clickableArea / 2);
}
