.specimen-modal {
    max-width: 600px;

    .ds-dialog {
        padding: 24px;
    }

    .specimen-modal-content {
        display: flex;
        flex-direction: column;

        p {
            margin: 10px;
        }
    }

    .specimen-modal-content__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 48px;
    }
}
