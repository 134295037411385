/* Design systen colors */
:root {
    --primary-blue: #006ccb;
    --dark-primary-blue: #0062b9;
    --light-primary-blue: #4694d9;
    --lightblue: #adccf5;

    --black: #192a3e;
    --grey: #707683;
    --grey-dark: #535b6d;
    --grey-25: rgba(121, 118, 131, 0.25);
    --dark-grey: #535b6d;
    --medium-grey: #c2cfe0;
    --light-grey: #f3f5f9;
    --white: #fff;
    --white-15: rgba(255, 255, 255, 0.15);

    --workmodes-solo: #3b29ab;
    --workmodes-solo-20: #cfceed;

    --mode-duo-primary-light: #9e00591f;
    --mode-duo-primary-medium: #9e0059;
    --mode-duo-primary-transparent: rgba(158, 0, 89, 0.09);

    --mode-duo-primary-dark: #4d133d;
    --mode-duo-secondary-medium: #7b61ff;
    --mode-duo-secondary-dark: #1b134d;

    --grey-700: #3c4257;
    --grey-500: #697386;
    --grey-200: #c1c9d2;

    --layout-darkblue: #00274a;
    --layout-greyblue: #334d6e;
    --layout-alice-blue: #f2f4fc;
    --layout-lightblue: #f4f7fe;
    --layout-containerincard: #f8fafc;
    --layout-grey: #fafafe;
    --layout-warm-white: #fffbfe;

    --alert-inf-default: #ffa726;
    --alert-inf-hover: #d98a17;
    --alert-inf-layoutbubble: #fae7cc;
    --alert-inf-chip-dark: #4c4d3f;
    --alert-inf-chip-light: #fff7eb;
    --alert-inf-dark: #d98a17;

    --alert-sup-default: #26a4ab;
    --alert-sup-hover: #3d8a8f;
    --alert-sup-bubble: #cfebed;
    --alert-sup-chip-dark: #205f74;
    --alert-sup-chip-light: #ebf8f9;
    --alert-sup-dark: #3d8a8f;

    --alert-conf-default: #8ac0ec;
    --alert-conf-hover: #7aadd6;
    --alert-conf-bubble: #e5f1fb;
    --alert-conf-chip-dark: #305d83;
    --alert-conf-chip-light: #e8f2fb;

    --left-plot-filled: #ecf5fc;

    --false-dark: #a81f00;
    --false: #c81d1d;
    --false-medium: #c81d1d40;
    --false-light: #fef9f9;
    --true: #186a3a;
    --true-medium: #186a3a40;
    --true-light: #f6faf8;
    --level: #ffb03b;

    --purple-dark: #5046b9;
    --purple-medium: #c4d3f9;
    --purple-light: #eaf0fd;

    --font-black: #192a3e;
    --font-grey: #707683;

    --objective-completed: #8ac0ec;
    --objective-disabled-completed: #8a9fec;
    --objective-inProgress: #cbe1ea;
    --objective-disabled-inProgress: #d6dff7;

    --danger: #e9d1d1;
    --danger-border: #dbb2b2;

    --background-color-primary: #fff;
    --background-color-secondary: #f2f6fb;

    --disabled-primary-light: rgba(243, 245, 249, 1);
    --disabled-primary: rgba(83, 91, 109, 1);

    --mode-solo-ai-primary: rgba(59, 41, 171, 1);
    --mode-teacher-primary: rgba(1, 102, 128, 1);
    --mode-duo-primary: rgba(158, 0, 89, 1);
    --mode-workshop-primary: rgba(0, 68, 176, 1);
    --mode-test-primary: rgba(25, 42, 62, 1);
    --mode-tuto-primary: rgba(168, 31, 0, 1);

    --mode-solo-ai-primary-light: rgba(0, 108, 203, 0.09);
    --mode-duo-primary-light: rgba(158, 0, 89, 0.09);
    --mode-test-primary-light: rgba(243, 245, 249, 1);
    --mode-solo-teacher-primary-light: rgba(25, 133, 161, 0.09);
    --mode-tuto-primary-light: rgba(252, 47, 0, 0.09);
    --mode-workshop-primary-light: rgba(58, 134, 255, 0.09);
    --mode-hover: rgba(244, 247, 254, 1);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
