@use "./_mixins.scss";
@use "./Variables.scss" as vars;

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"),
        url("../fonts/materialIcons/MaterialIcons-Regular.ttf")
            format("truetype");
}

@font-face {
    font-family: "Staatliches";
    font-style: normal;
    font-weight: 400;
    src: local("Staatliches"), local("Staatliches-Regular"),
        url("../fonts/staatliches/Staatliches-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: local(""), local("Roboto-Light"),
        url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local(""), local("Roboto-Regular"),
        url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: local(""), local("Roboto-Medium"),
        url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local(""), local("Roboto-Bold"),
        url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: "Roboto", sans-serif;
    background: var(--block-page);

    --breakpoint-large: #{vars.$breakpoint-large};
    --breakpoint-medium: #{vars.$breakpoint-medium};
}

button {
    font-size: 1em;
}

//HEADING
h1,
h2 {
    font-weight: 500;
}

.staatliches {
    font-family: "Staatliches";
}

h2 {
    font-size: 1.7em;
}

p,
li {
    line-height: 1.4em;
}

button {
    font-family: inherit;
    background: none;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}

// ICONS
.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

.icon-container-btn {
    margin-left: auto;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

// LISTS
ul {
    list-style-type: none;
}

li {
    list-style: none;
    list-style-type: none;
}

// LINKS
a {
    text-decoration: none;
    color: inherit;
}

// Rules to have a sticky FOOTER
#root {
    height: 100%;
}

.mathjax-inline-container {
    font-size: 1rem;
}
